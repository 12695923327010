@tailwind base;
@tailwind components;
@tailwind utilities;

/* Animation of wave */
.parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 14s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 17s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 20s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 27s;
  }
  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }